import { defineStore } from 'pinia';
import { Auth } from '@js/api';

import { AUTH } from '@js/const';
import { useLocalStorage } from '@vueuse/core';
import {
  parseISO, formatISO, addMinutes, isFuture,
} from 'date-fns';

export default defineStore({
  id: 'app',
  state: () => ({
    step: AUTH.CREDENTIALS,
    blockedUntil: useLocalStorage('ssoBlockedUntil', null),
    blockedFor: useLocalStorage('ssoBlockedFor', null),
    username: '',
    password: '', // TODO: Double check the security of this
    splash: null,
    ssoOrigin: null,
    logo: null,
    isLoading: false,
  }),
  getters: {
    isBlocked: (state) => {
      if (state.blockedUntil !== null) {
        return !!isFuture(parseISO(state.blockedUntil));
      }
      return false;
    },
  },
  actions: {
    setBlockedUntil(minutes) {
      this.$patch((state) => {
        state.blockedUntil = formatISO(addMinutes(new Date(), minutes));
        state.blockedFor = minutes;
      });
    },
    resetBlockedUntil() {
      this.$patch((state) => {
        state.blockedUntil = null;
        state.blockedFor = null;
      });
    },
    handleResponse(res) {
      if ('login' in res) {
        window.location.href = '/';
      } else if ('step' in res) {
        this.step = res.step;
      } else if (!('errors' in res)) {
        console.error('Invalid server response. LOGIN or STEP required.');
      }
      this.isLoading = false;
    },
    sendAuth(data = {}) {
      let endpoint = Auth.sendAuth;
      // Password reset needs a different endpoint
      if ([AUTH.RESET, AUTH.FORGOT].includes(this.step)) {
        endpoint = Auth.sendPasswordReset;
      }
      this.isLoading = true;
      return endpoint().fetch(this.step, data).then(({ data: res }) => {
        this.handleResponse(res);
      }).catch((err) => {
        this.handleResponse(err.response.data);
        /**
         * Pass error down to invoke component
         */
        return new Promise((resolve, reject) => { reject(err); });
      });
    },

  },
});
