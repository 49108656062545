<template>
  <div
    ref="digitEntry"
    class="auth__code d-flex align-items-center justify-content-sm-between"
  >
    <input
      v-for="index in codeLength"
      :key="index"
      :value="digits[index-1]"
      type="text"
      inputmode="numeric"
      pattern="[0-9]*"
      required
      class="form-control form-control-lg me-3 text-center px-0"
      @input="checkAndProgress($event,index-1)"
      @keydown="handleBackspace"
      @focus="selectDigitField"
      @click="selectDigitField"
    >
  </div>
  <input
    v-model="authCode"
    type="hidden"
    autocomplete="one-time-code"
    :name="codeFieldName"
  >
</template>

<script setup>
import {
  ref, computed, defineProps, toRefs, onMounted, watchEffect, defineEmits,
} from 'vue';
import { useExternalClipboard } from '@js/composables/useClipboard';

// Props
const props = defineProps({
  codeFieldName: { type: String, required: true },
  codeLength: { type: Number, default: 6 },
});

const { codeFieldName, codeLength } = toRefs(props);

// Emits
const emit = defineEmits(['valid', 'update:code']);

// Split 2FA Code
const digits = ref([]);

for (let i = 0; i < codeLength.value; i += 1) {
  digits.value.push('');
}

// Full 2FA Code
const authCode = computed({
  get: () => digits.value.join(''),
  set: (value) => {
    const splitValue = value.split('');
    digits.value.splice(0, digits.value.length, ...splitValue);
  },
});

// Validation Emit
watchEffect(() => {
  emit('valid', authCode.value.length === codeLength.value);
  emit('update:code', authCode.value);
});

// Automatic clipboard paste for 2FA
const { clipboard: clipboardCode } = useExternalClipboard();
watchEffect(() => {
  if (clipboardCode.value.length === codeLength.value && clipboardCode.value.match(/^[0-9]+$/g)) {
    authCode.value = clipboardCode.value;
  }
});

// Focus Element Helper
const focusElement = (ele) => {
  if (ele !== null) {
    ele.focus();
  }
};

// Check input and navigate through code fields
const checkAndProgress = (event, index) => {
  const { nextElementSibling, previousElementSibling, value } = event.target;
  if (!Number.isNaN(parseInt(value, 10))) {
    switch (value.length) {
      case 0:
        digits.value[index] = '';
        focusElement(previousElementSibling);
        break;
      case 1:
        digits.value[index] = value;
        focusElement(nextElementSibling);
        break;
      case codeLength.value:
        digits.value = value.split('');
        break;
      default:
        digits.value[index] = '';
        event.target.value = '';
    }
  } else {
    // Reset field and digit
    digits.value[index] = '';
    event.target.value = '';
  }
};

// Select all in an input field
const selectDigitField = (event) => event.target.setSelectionRange(0, event.target.value.length);

// Handle keyboard navigation
const handleBackspace = (event) => {
  const { previousElementSibling, nextElementSibling, value } = event.target;
  const { key } = event;
  if (['Backspace'].includes(key) && value.length === 0) {
    focusElement(previousElementSibling);
  }
  if (['ArrowLeft'].includes(key)) {
    focusElement(previousElementSibling);
  }
  if (['ArrowRight'].includes(key)) {
    focusElement(nextElementSibling);
  }
};

const digitEntry = ref('');
onMounted(() => {
  focusElement(digitEntry.value.firstElementChild);
});

</script>

<style lang="scss" scoped>
.auth__code {
  max-width: 25rem;
}
</style>
