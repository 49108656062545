import {
  ref, UnwrapRef, Ref, watchEffect,
} from 'vue';
import { useWindowFocus } from '@vueuse/core';

/**
 *
 * @returns {{clipboard: Ref<UnwrapRef<string>>}} Reactive clipboard content
 */
function useExternalClipboard() {
  const clipboard = ref('');
  const allowed = ref(true);
  const focused = useWindowFocus();

  watchEffect(() => {
    if (focused.value && allowed.value) {
      navigator.permissions.query({ name: 'clipboard-read' }).then((result) => {
        if (result.state === 'granted' || result.state === 'prompt') {
          navigator.clipboard.readText().then((copiedCode) => {
            clipboard.value = copiedCode;
          });
        } else {
          allowed.value = false;
        }
      }).catch(() => {
        // Permission denied
        allowed.value = false;
      });
    }
  });

  return { clipboard };
}

export { useExternalClipboard };
