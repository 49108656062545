import {
  ref,
  unref,
  isRef,
  watchEffect,
  Ref,
} from 'vue';

/**
 * @type {Location}
 */
const getURL = window.location;

const baseURL = `${getURL.protocol}//${getURL.host}`;

/**
 *
 * @param {Ref<Array<string|null>|string> | string | null | Array<string|null>} src
 */
export default (src) => {
  const loaded = ref(false);
  const error = ref(null);
  /**
   *
   */
  const loadImages = () => {
    loaded.value = false;
    /**
     *
     * @type {Ref<Array<string|null>>}
     */
    const sources = ref([]);

    /**
     *
     * @type {Ref<Promise[]>}
     */
    const promises = ref([]);

    if (Array.isArray(unref(src))) {
      sources.value = unref(src);
    } else if (typeof unref(src) !== 'undefined' && unref(src) !== null) {
      sources.value.push(unref(src));
    }

    sources.value.forEach((source) => {
      if (source !== null) {
        promises.value.push(new Promise((resolve, reject) => {
          const imagePlaceholder = new Image();
          imagePlaceholder.onload = () => {
            resolve();
          };
          imagePlaceholder.onerror = (err) => {
            reject(err);
          };
          imagePlaceholder.src = baseURL + source;
        }));
      }
    });

    Promise.all(promises.value).catch((err) => {
      error.value = err;
    }).finally(() => {
      loaded.value = true;
    });
  };

  if (isRef(src)) {
    // setup reactive re-fetch if input URL is a ref
    watchEffect(loadImages);
  } else {
    // otherwise, just fetch once
    // and avoid the overhead of a watcher
    loadImages();
  }

  return {
    error,
    loaded,
  };
};
