<template>
  <div
    class="login__bg"
    :class="[{'login__bg--custom':hasSplash}]"
  >
    <transition name="zoom-out">
      <div
        v-if="hasSplash"
        v-show="loaded"
        class="login__img"
        :style="`background-image: url('${splashSrc}')`"
      />
    </transition>
  </div>
</template>

<script setup>
import {
  defineProps, toRefs, computed,
} from 'vue';
import useImageLoader from '@js/composables/useImageLoader';

const props = defineProps({
  splashSrc: { type: String, default: null },
});
const { splashSrc } = toRefs(props);

const hasSplash = computed(() => splashSrc.value !== null);

const { loaded } = useImageLoader(splashSrc.value);

</script>
