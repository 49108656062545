import authBase from '@js/pages/app';
import { ref } from 'vue';
import LoginSplashBackground from '@js/components/global/LoginSplashBackground.vue';
import SingleSignOnPage from '@js/views/SingleSignOn.vue';
import { createPinia } from 'pinia';
import * as Sentry from '@sentry/vue';
import SecurityTwoFactorField from '../components/security/SecurityTwoFactorField.vue';

const pinia = createPinia();

/**
 * Page Level Root Component
 * https://v3.vuejs.org/guide/instance.html#the-root-component
 */

const app = authBase({
  setup() {
    const authValid = ref(false);
    return { authValid };
  },
  components: {
    SecurityTwoFactorField,
    LoginSplashBackground,
    SingleSignOnPage,
  },
});

Sentry.init({
  app,
  dsn: 'https://b66fb28a736e0d7ec8f372f868603f5a@o4506665035235328.ingest.sentry.io/4506665062301696',
  tracingOptions: {
    trackComponents: true,
    timeout: 1000,
    hooks: ['mount', 'update', 'unmount'],
  },
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/.*miadmin\..*/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

Sentry.setUser(window?.user ?? null);

app.use(pinia).mount('#app');
